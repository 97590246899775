import StoreWrapper from "components/storeWrapper"
import LibraryLayout from "layouts/libraryLayout"
import MainLayout from "layouts/mainLayout"
import React from "react"
import routes from "services/routes"

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export { ContextWrapper as wrapRootElement } from "contextWrapper"

//
// Wrap pages in layouts to avoid unmounting
//
// NOTE: Keep in sync with gatsby-ssr.js!
// See: https://www.gatsbyjs.com/docs/how-to/routing/layout-components/#how-to-prevent-layout-components-from-unmounting
//
export const wrapPageElement = ({ element, props }) => {
  // Wrap store pages, apart from the store chooser
  if (
    props.path?.includes("/store/") &&
    !props.path?.includes(routes.STORE_CHOOSER) &&
    !props.path?.includes(routes.STORE_WARNING)
  ) {
    return (
      <MainLayout>
        <StoreWrapper {...props}>{element}</StoreWrapper>
      </MainLayout>
    )
  }

  // Library layout keeps heading and Subnav mounted to prevent flicker
  if (props.location?.pathname.startsWith(routes.LIBRARY)) {
    return (
      <MainLayout>
        <LibraryLayout>{element}</LibraryLayout>
      </MainLayout>
    )
  }

  // Wrap everthing else in the main layout
  return <MainLayout>{element}</MainLayout>
}

//
// Run when browser runtime starts
// See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onClientEntry
//
export const onClientEntry = () => {
  // Enable "why did you render?" tracking.
  // See: https://www.gatsbyjs.com/plugins/gatsby-plugin-why-did-you-render/
  //
  // if (process.env.NODE_ENV !== "production") {
  //   const whyDidYouRender = require("@welldone-software/why-did-you-render")
  //   whyDidYouRender(React, {
  //     trackAllPureComponents: true,
  //     // logOnDifferentValues: true,
  //     collapseGroups: true,
  //   })
  // }

  // Make sure browser isn't using history API for scroll restoration
  if (window.history.scrollRestoration) {
    window.history.scrollRestoration = "manual"
  }
}

/**
 * Manually control scroll restoration, which doesn't work at all for this product.
 * This now returns "false", whch stops it working in theory.
 * See `useScrollRestoration.js` for our own implementation.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll
 * See: https://stackoverflow.com/a/68687333
 * See: https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-react-router-scroll/src/scroll-handler.tsx
 * See: https://github.com/gatsbyjs/gatsby/pull/27384
 * See: https://github.com/gatsbyjs/gatsby/issues/19632
 * See: https://blog.logrocket.com/implementing-scroll-restoration-in-ecommerce-react-apps/
 */
export function shouldUpdateScroll() {
  return false
}
