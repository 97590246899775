import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useLocation } from "@reach/router"
import AudiobookIcon from "components/icons/contentTypes/audiobookIcon"
import PodcastIcon from "components/icons/contentTypes/podcastIcon"
import RadioIcon from "components/icons/contentTypes/stationIcon"
import BreakpointContext from "context/breakpointContext"
import { useCountries } from "context/countryContext"
import {
  SELECTED_ITEM,
  useSelectedItemDispatchContext,
} from "context/selectedItemContext"
import { ContentType } from "helpers/contentTypes"
import { size } from "helpers/responsiveSize"
import { useCallToAction } from "hooks/useCallToAction"
import { useMountOnLoad } from "hooks/useMountOnLoad"
import GridLayout from "layouts/gridLayout"
import PropTypes from "prop-types"
import React, { useCallback, useContext, useEffect, useMemo } from "react"
import Button from "../controls/button"
import AspectRatioBox from "../utils/aspectRatioBox"
import RemoteImage from "../utils/remoteImage"
import CloseButton from "./closeButton"
import ContentCardMetadata from "./contentCardMetadata"
import Description from "./description"

/**
 * A detailed content card
 *
 * Data required from a Content Item:
 * — ref
 * — type
 * — artwork
 * — title
 * — author
 * — narrator
 * — audience
 * — duration
 * — publicationDate
 * — publisher
 * — categories
 */
const ContentCardDetail = ({
  contentItem,
  ctaButtonType,
  ctaButtonOnClick,
  ctaButtonIsLoading,
  errorMessage,
  onDismiss,
}) => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // Capture mount state of component to counter-act
  // Safari bug where line-clamping is rendered strangely.
  const mounted = useMountOnLoad()

  // Grab the location from Reach Router for Metadata
  const location = useLocation()

  // Store country
  const { storeURL } = useCountries()

  // Selected item dispatcher
  const dispatchSelectedItem = useSelectedItemDispatchContext()

  // Get CTA detail
  const {
    isLoaded: isCTALoaded,
    text: ctaText,
    isAvailable: ctaIsAvailable,
    explanation: ctaExplanation,
  } = useCallToAction(contentItem)

  // Author/authors
  const author = useMemo(() => {
    let author = contentItem.author
    let author2 = contentItem.author2
    if (author && author2) {
      return `${author} & ${author2}`
    } else if (author) {
      return author
    } else {
      return undefined
    }
  }, [contentItem.author, contentItem.author2])

  /**
   * Card dismissed on close
   */
  const cardDismissed = useCallback(() => {
    // Hide the card
    dispatchSelectedItem({
      type: SELECTED_ITEM,
      payload: undefined,
    })
    // Invoke the callback
    onDismiss()
  }, [dispatchSelectedItem, onDismiss])

  /**
   * Handle share button click
   */
  const onShareClicked = useCallback(() => {
    // Construct the URL to share
    const shareURL = `${location.origin}/${storeURL}/store/${contentItem.type}s/${contentItem.slug}`
    // Open the share URL in a new window
    window.open(shareURL)
  }, [contentItem, location.origin, storeURL])

  // Make sure card is dimissed properly
  useEffect(() => {
    return function cleanup() {
      // Card has been displayed, so clean up to make sure
      // card isn't visible again by accident.
      if (mounted) {
        cardDismissed()
      }
    }
  }, [mounted, cardDismissed])

  return (
    <GridLayout type="fixed" className="items-center h-screen">
      {/* Card outline */}
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <div
        role="dialog"
        style={{
          marginTop: size(bpt, "12.5vw", "4vw", "6vw", "4vw"),
          marginBottom: size(bpt, "18vw", "12.5vw", "12.5vw", "12.5vw"),
        }}
        className="
          col-start-1 tablet:col-start-3 desktop:col-start-4
          col-span-full tablet:col-span-8 desktop:col-span-6
          laptop:mx-11 desktop:mx-0
          rounded-2xl tablet:rounded-5xl
          bg-grey-light
          neumorphic-4-outer tablet:neumorphic-6-outer laptop:neumorphic-7-outer
          relative"
        onClick={(event) => {
          // Prevent clicks on card dismissing the dialog
          event.stopPropagation()
        }}
        onKeyDown={(event) => {
          // Prevent clicks on card dismissing the dialog
          event.stopPropagation()
        }}
      >
        {/* Close button */}
        <CloseButton onClick={cardDismissed} />

        {/* Image container */}
        <AspectRatioBox mobileRatio="100%">
          {contentItem?.artwork && (
            <RemoteImage
              imagePath={contentItem.artwork}
              aspectRatio="1:1"
              mobileImageSize={280}
              tabletImageSize={440}
              laptopImageSize={500}
              desktopImageSize={612}
              className="
                w-full
                h-auto
                rounded-tl-2xl tablet:rounded-tl-5xl
                rounded-tr-2xl tablet:rounded-tr-5xl"
            />
          )}
          {contentItem && !contentItem.artwork && (
            <div
              className="
                w-full
                h-full
                bg-grey
                rounded-tl-2xl tablet:rounded-tl-5xl
                rounded-tr-2xl tablet:rounded-tr-5xl
                flex
                items-center
                justify-center"
            >
              <FontAwesomeIcon
                icon="image"
                fixedWidth={true}
                size={size(bpt, "5x", "10x", "10x", "10x")}
                className="text-grey-light"
              />
            </div>
          )}
        </AspectRatioBox>

        {/* Inner container */}
        <div
          style={{
            paddingTop: size(bpt, "4vw"),
            paddingBottom: size(bpt, "4vw"),
            paddingLeft: size(bpt, "5vw"),
            paddingRight: size(bpt, "5vw"),
          }}
          className="
            flex flex-col
            tablet:pt-6
            tablet:pb-8
            tablet:px-8 desktop:px-9"
        >
          <div className="flex flex-row justify-between ">
            {/* Icon */}
            <div
              style={{
                width: size(bpt, "5vw", "3vw", 32, 32),
                height: size(bpt, "5vw", "3vw", 32, 32),
              }}
              className="laptop:ml-0.5"
            >
              {contentItem?.type === ContentType.AUDIOBOOK && <AudiobookIcon />}
              {contentItem?.type === ContentType.PODCAST && <PodcastIcon />}
              {contentItem?.type === ContentType.STATION && <RadioIcon />}
            </div>

            {/* Share button */}
            <Button
              shadowHeight={1}
              // size={size(bpt, undefined, "xs", "xs", "sm")}
              onClick={onShareClicked}
              style={{
                width: size(bpt, "25vw", "12vw", "10vw", "9vw"),
                height: size(bpt, "5vw", "3vw", "2.5vw", "2vw"),
              }}
            >
              <div
                style={{
                  gap: size(bpt, "1.5vw", "1vw", "0.75vw", "0.6vw"),
                }}
                className="grid grid-flow-col items-center"
              >
                <span
                  style={{
                    fontSize: size(bpt, "3vw", "1.5vw", "1.2vw", "1vw"),
                  }}
                  className="uppercase font-extrabold"
                >
                  Share
                </span>
                <FontAwesomeIcon
                  icon={["fa", "share-square"]}
                  style={{
                    width: size(bpt, "3.5vw", "1.8vw", "1.4vw", "1.2vw"),
                    height: size(bpt, "3.5vw", "1.8vw", "1.4vw", "1.2vw"),
                    marginTop: size(
                      bpt,
                      "-0.5vw",
                      "-0.5vw",
                      "-0.4vw",
                      "-0.3vw"
                    ),
                  }}
                />
              </div>
            </Button>
          </div>

          {/* Title */}
          <p
            style={{
              fontSize: size(bpt, "5vw"),
            }}
            className="
              tablet:text-xl laptop:text-2xl
              font-bold
              pt-2 tablet:pt-3 laptop:pt-4
              leading-tight
              line-clamp-2"
            dangerouslySetInnerHTML={{
              __html: contentItem?.title,
            }}
          ></p>

          {contentItem?.type === ContentType.AUDIOBOOK && (
            <>
              {/* Author */}
              {author && (
                <>
                  <div
                    style={{
                      fontSize: size(bpt, "4vw"),
                      marginTop: size(bpt, "1.5vw"),
                    }}
                    className="
                      tablet:mt-2.75 laptop:mt-1.5 desktop:mt-1.25
                      laptop:ml-0.5
                      tablet:text-sm laptop:text-base
                      tracking-wide
                      leading-tight"
                  >
                    Written by {author}
                  </div>
                </>
              )}

              {/* Narrator */}
              {contentItem?.narrator && (
                <>
                  <div
                    data-nosnippet // See: https://developers.google.com/search/docs/advanced/robots/robots_meta_tag#data-nosnippet-attr
                    style={{
                      fontSize: size(bpt, "4vw"),
                      marginTop: size(bpt, "1.5vw"),
                    }}
                    className="
                      laptop:mt-1.5 desktop:mt-1.25
                      tablet:text-sm laptop:text-base
                      laptop:ml-0.5"
                  >
                    Narrated by {contentItem.narrator}
                  </div>
                </>
              )}
            </>
          )}

          {/* Progress & Time remaining */}
          {/* {(contentType === ContentType.AUDIOBOOK || contentType === ContentType.PODCAST) && (
            <div
              style={{
                marginTop: size(bpt, "3vw"),
              }}
              className="
                flex
                tablet:mt-4
                laptop:ml-1"
            >
              <ProgressBar
                fillColour={
                  contentType === ContentType.AUDIOBOOK
                    ? "bg-blue"
                    : contentType === ContentType.PODCAST
                    ? "bg-orange"
                    : ""
                }
                progress={80}
                mobileRatio="3.5%"
                tabletRatio="2.6%"
                laptopRatio="3%"
                desktopRatio="2.8%"
                style={{
                  width: size(bpt, "62%", "66%", "63%", "50%"),
                }}
              />
              <p
                style={{
                  fontSize: size(bpt, "3vw"),
                  marginTop: size(bpt, "-1vw"),
                }}
                className={`
                  ${
                    contentType === ContentType.AUDIOBOOK
                      ? "text-blue"
                      : contentType === ContentType.PODCAST
                      ? "text-orange"
                      : ""
                  }
                  tablet:text-sm
                  tablet:-mt-1 
                  ml-2.5 laptop:ml-5
              `}
              >
                14h 12m left
              </p>
            </div>
          )} */}

          {/* CTA */}
          <Button
            type={ctaButtonType}
            text={ctaText}
            loading={!isCTALoaded || ctaButtonIsLoading}
            disabled={!isCTALoaded || !ctaIsAvailable}
            shadowHeight={size(bpt, 2, 5, 5, 5)}
            size={size(bpt, undefined, "lg", "lg", "lg")}
            style={{
              marginTop: size(bpt, "5vw"),
              width: size(bpt, "34vw", "23vw", "14vw", "12vw"),
              height: size(bpt, "8vw"),
            }}
            styleInner={{
              fontSize: size(bpt, "3.5vw"),
            }}
            className="
              tablet:mt-4 laptop:mt-5 desktop:mt-6
              tablet:w-36 laptop:w-40"
            onClick={ctaButtonOnClick}
          />

          {/* Error message */}
          {errorMessage && (
            <p
              className="
                mt-5
                font-bold
                text-red 
                text-sm"
            >
              {errorMessage}
            </p>
          )}

          {/* CTA explanation */}
          <div
            data-nosnippet // See: https://developers.google.com/search/docs/advanced/robots/robots_meta_tag#data-nosnippet-attr
            style={{
              fontSize: size(bpt, "3.3vw"),
              marginTop: size(bpt, "4vw"),
            }}
            className="
              tablet:text-sm laptop:text-base
              ml-1 tablet:ml-0 laptop:ml-1
              tablet:mt-4 laptop:mt-5
              font-medium text-red"
          >
            {!ctaButtonIsLoading && ctaExplanation}
          </div>

          {/* Counter-act strange Safari line-clamp rendering bug. Also see mainLayout.css. */}
          {mounted && (
            <Description
              contentType={contentItem?.type}
              contentItem={contentItem}
            />
          )}
          <ContentCardMetadata contentItem={contentItem} />
        </div>
      </div>
    </GridLayout>
  )
}

ContentCardDetail.propTypes = {
  contentItem: PropTypes.object.isRequired,
  ctaButtonType: PropTypes.string.isRequired,
  ctaButtonOnClick: PropTypes.func.isRequired,
  ctaButtonIsLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  onDismiss: PropTypes.func.isRequired,
}

export default ContentCardDetail
ContentCardDetail.whyDidYouRender = true
